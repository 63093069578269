import ApiService from "@/core/services/ApiService";
import { Coupon } from "@/core/services/responsesModel/coupon"

// 根據codesGuid查詢Coupon
export function getCoupons(request): Promise<Array<Coupon>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Coupons/Query`, request).then(res => {
            resolve(res.data.coupons)
        }).catch(err => {
            reject(err)
        })
    })
}

// 查詢空間內的優惠卷
export function getCouponsBySpace(request): Promise<Array<Coupon>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Coupons/QueryBySpace`, request).then(res => {
            resolve(res.data.coupons)
        }).catch(err => {
            reject(err)
        })
    })
}

// 查詢優惠卷使用紀錄
export function getCouponLog(request): Promise<Array<Coupon>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Coupons/QueryLogByCoupon`, request).then(res => {
            resolve(res.data.coupons)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增優惠卷
export function addCoupon(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Coupons/Add`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 編輯優惠卷
export function editCoupon(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Coupons/UpdateData`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}