
import { defineComponent, onMounted, reactive, ref } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addCoupon } from "@/core/services/api/coupons";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      number: [
        {
          required: true,
          message: "請填選數量",
          trigger: "blur",
        },
      ],
      datetimerange: [
        {
          required: true,
          message: "請填選起訖時間",
          trigger: "blur",
        },
      ],
      discount: [
        {
          required: true,
          message: "請填選折扣",
          trigger: "blur",
        },
      ],
      amount: [
        {
          required: true,
          message: "請填選金額",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "請填寫標題",
          trigger: "blur",
        },
      ],
    });
    const form:any = reactive({
      once: true,
      number: 1,
      datetimerange: [],
      mode: "Amount",
      discount: null,
      title: "",
      description: "",
    });
    const modeOptions = [
      {
        value: "Amount",
        label: "金額",
      },
      {
        value: "Percent",
        label: "折數",
      },
    ];

    const reseatForm = () => {
      Object.assign(form, {
        once: true,
        number: 1,
        datetimerange: [],
        mode: "Amount",
        discount: null,
        title: "",
        description: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "確定要新增優惠券嗎?",
            icon: "warning",
            text: "新增後將無法刪除!",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                space: currentSpaceGuid,
                once: form.once,
                number: form.number,
                mode: form.mode,
                discount:
                  form.mode === "Percent"
                    ? form.discount * 0.01
                    : form.discount,
                beginTime: getUtcTimeString(form.datetimerange[0]),
                endTime: getUtcTimeString(form.datetimerange[1]),
                title: form.title,
                description: form.description,
              };
              console.log("request", request);
              await addCoupon(request);
              await Swal.fire("新增成功!", "", "success");
              emit("onAddCoupon");
              reseatForm();
            }
          });
        }
      });
    };

    onMounted(() => {
      let addCouponModal: any = document.getElementById(
        "addCoupon-modal"
      );
      addCouponModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
      modeOptions,
    };
  },
});
