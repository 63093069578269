
import { defineComponent, reactive, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { editCoupon } from "@/core/services/api/coupons";

export default defineComponent({
  props: ["coupon"],
  setup(props,{emit}) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      datetimerange: [
        {
          required: true,
          message: "請填選起訖時間",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "請填寫標題",
          trigger: "blur",
        },
      ],
    });
    const form: any = reactive({
      datetimerange: [],
      title: "",
      description: "",
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "確定要編輯優惠券嗎?",
            icon: "warning",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                guid: props.coupon.coupon.guid,
                beginTime: getUtcTimeString(form.datetimerange[0]),
                endTime: getUtcTimeString(form.datetimerange[1]),
                title: form.title,
                description: form.description,
              };
                await editCoupon(request);
                await Swal.fire("編輯成功!", "", "success");
                emit("onEditAddCoupon");
            }
          });
        }
      });
    };

    const setFormData = () => {
      let coupon = props.coupon.coupon;
      Object.assign(form, {
        datetimerange: [
          getLocalTimeString(coupon.beginTime),
          getLocalTimeString(coupon.endTime),
        ],
        title: coupon.title,
        description: coupon.description,
      });
    };

    watch(
      props,
      () => {
        setFormData();
      },
      { deep: true }
    );

    return {
      rules,
      formRef,
      form,
      submit,
    };
  },
});
