<template>
  <div class="row" style="font-size: 15px">
    <div class="col">優惠券數量</div>
    <div class="col">{{ props.coupon.codesLength }}</div>
  </div>
  <hr />
  <div class="row" style="font-size: 15px">
    <div class="col">已使用次數</div>
    <div class="col">{{ displayOrderRecordsLength }}</div>
  </div>
  <hr />
  <div class="row" style="font-size: 15px">
    <div class="col">已綁定人數</div>
    <div class="col">{{ bindsLength }}</div>
  </div>
  <div class="d-flex mt-10">
    <el-date-picker
      v-model="datePicker"
      type="datetimerange"
      range-separator="至"
      start-placeholder="開始日期"
      end-placeholder="結束日期"
    >
    </el-date-picker>
    <div>
      <button
        @click="download()"
        type="button"
        class="btn btn-primary col ms-5"
      >
        下載使用紀錄
      </button>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import { getOrderRecord } from "@/core/services/api/orderRecord";
import { getCouponLog } from "@/core/services/api/coupons";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getMembersBySpace } from "@/core/services/api/members";
import moment from "moment";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  props: ["coupon"],
  setup(props) {
    let spacesMembers:any;
    let facilitys: any;
    let detailCoupon: any = {
      binds: [],
    };
    let customOrderRecords;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const displayOrderRecords = reactive([]);
    const displayOrderRecordsLength = ref(0);
    const bindsLength = ref(0);
    const datePicker = ref([]);
    const setCouponsBySpace = async () => {
      let request = {
        coupon: props.coupon.coupon.guid,
      };
      let orderRecord: any = await getOrderRecord(request);
      displayOrderRecordsLength.value = orderRecord.length;
    };

    const setCouponLog = async () => {
      let request = {
        coupon: props.coupon.coupon.guid,
      };
      let couponLog: any = await getCouponLog(request);
      let bindLogs: any = couponLog.filter((o) => o.user != null);
      bindLogs = Array.from(new Set(bindLogs.map((o) => o.user)));
      bindsLength.value = bindLogs.length;
    };

    const handleGetFacilityBySpace = async () => {
      let facilitys = await getFacilityBySpace(currentSpaceGuid!);
      return facilitys;
    };

    const handleGetMembersBySpace = async () => {
      let members = await getMembersBySpace(currentSpaceGuid!)
      return members
    }

    const getUserByGuid = (guid) => {
      
      let filterUsers = spacesMembers.filter(
        (member) => member.guid == guid
      );
      return filterUsers[0];
    };

    const getFacilityByGuid = (guid) => {
      
      let filterFacilitys = facilitys.filter(
        (facility) => facility.facility.guid == guid
      );
      return filterFacilitys[0].facility;
    };

    // 取得使用紀錄
    const fetchLog = async () => {
      let request = {
        coupon: props.coupon.coupon.guid,
      };
      let couponLogs: any = await getCouponLog(request);
      const bindLogs: any = couponLogs.filter((o) => o.user != null);
      detailCoupon.binds = Array.from(new Set(bindLogs.map((o) => o.user)));
      detailCoupon.guid = props.coupon.coupon.guid;
      let orderRecord: any = await getOrderRecord(request);
      customOrderRecords = [];
      orderRecord.forEach((o) => {
        o.paymentRecords.forEach((p) => {
          p.tokens.forEach((t) => {
            const range = moment.duration(moment(t.endTime).diff(t.beginTime));
            customOrderRecords.push({
              user: o.user,
              orderRunningNumber: o.runningNumber,
              facilityName: getFacilityByGuid(t.facility).name,
              isCancel: o.status === "Cancel" ? "是" : "否",
              paymentRunningRnumber: p.runningNumber,
              beginTime: t.beginTime,
              endTime: t.endTime,
              useTime: range.hours() + "時" + range.minutes() + "分",
              logCreateTime: p.logCreateTime,
            });
          });
        });
      });
    };

    const download = () => {
           //要匯出的json資料
      var jsonData:any = [];
      customOrderRecords.forEach((o) => {
        if (
          !moment(datePicker.value[0]).isAfter(o.logCreateTime) &&
          !moment(o.logCreateTime).isAfter(datePicker.value[1])
        ) {
          jsonData.push({
            userName: getUserByGuid(o.user).name,
            orderNumber: o.orderRunningNumber,
            facilityName: o.facilityName,
            isCancel: o.isCancel,
            logCreateTime: getLocalTimeString(o.logCreateTime),
            beginTime: getLocalTimeString(o.beginTime),
            endTime: getLocalTimeString(o.endTime),
            useTime: o.useTime,
          });
        }
      });

      //列標題，逗號隔開，每一個逗號就是隔開一個單元格
      let str = `使用者,訂單編號,設施,是否取消,使用優惠券時間,開始時間,結束時間,使用時間\n`;
      //增加\t為了不讓表格顯示科學計數法或者其他格式
      for (let i = 0; i < jsonData.length; i++) {
        for (let item in jsonData[i]) {
          str += `${jsonData[i][item] + "\t"},`;
        }
        str += "\n";
      }
      //encodeURIComponent解決中文亂碼
      let uri = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
      //通過建立a標籤實現
      var link = document.createElement("a");
      link.href = uri;
      //對下載的檔案命名
      link.download = `)_優惠券使用紀錄.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    watch(
      props,
      async () => {
        await setCouponsBySpace();
        await setCouponLog();
        facilitys = await handleGetFacilityBySpace();
        spacesMembers = await handleGetMembersBySpace();
        await fetchLog();
      },
      { deep: true }
    );

    return {
      props,
      displayOrderRecordsLength,
      bindsLength,
      datePicker,
      download,
    };
  },
});
</script>

<style scoped>
</style>