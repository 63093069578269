<template>
  <el-form
    @submit.prevent="submit()"
    :model="form"
    :rules="rules"
    ref="formRef"
    class="form"
  >
    <div>
      <div class="mb-3">
        <el-switch
          v-model="form.once"
          active-text="單次使用"
          inactive-text="重複使用"
        >
        </el-switch>
      </div>

      <template v-if="form.once">
        <label class="fs-6 fw-bold mb-2">
          <span class="required">數量</span>
        </label>
        <el-form-item prop="number">
          <el-input type="number" v-model="form.number" :min="1"></el-input>
        </el-form-item>
      </template>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">起訖時間</span>
      </label>
      <el-form-item prop="datetimerange">
        <el-date-picker
          v-model="form.datetimerange"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
        >
        </el-date-picker>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span>折扣模式</span>
      </label>
      <el-form-item>
        <el-select v-model="form.mode" placeholder="請選擇折扣模式">
          <el-option
            v-for="item in modeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="form.mode == 'Amount'">
        <label class="fs-6 fw-bold mb-2">
          <span class="required">折扣金額</span>
        </label>
        <el-form-item prop="discount">
          <el-input type="number" :min="0" v-model="form.discount">
            <template #append>$</template>
          </el-input>
        </el-form-item>
      </template>
      <template v-if="form.mode == 'Percent'">
        <label class="fs-6 fw-bold mb-2">
          <span class="required">折扣折數</span>
        </label>
        <el-form-item prop="discount">
          <el-input type="number" :min="0" :max="99" v-model="form.discount">
            <template #append>折(%)</template>
          </el-input>
          <div>
            <i>折扣折數為"0"，則為免費</i>
          </div>
        </el-form-item>
      </template>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">標題</span>
      </label>
      <el-form-item prop="title">
        <el-input v-model="form.title" placeholder="請輸入標題"></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span>內容</span>
      </label>
      <el-form-item>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="請輸入內容"
          v-model="form.description"
        >
        </el-input>
      </el-form-item>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addCoupon } from "@/core/services/api/coupons";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      number: [
        {
          required: true,
          message: "請填選數量",
          trigger: "blur",
        },
      ],
      datetimerange: [
        {
          required: true,
          message: "請填選起訖時間",
          trigger: "blur",
        },
      ],
      discount: [
        {
          required: true,
          message: "請填選折扣",
          trigger: "blur",
        },
      ],
      amount: [
        {
          required: true,
          message: "請填選金額",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "請填寫標題",
          trigger: "blur",
        },
      ],
    });
    const form:any = reactive({
      once: true,
      number: 1,
      datetimerange: [],
      mode: "Amount",
      discount: null,
      title: "",
      description: "",
    });
    const modeOptions = [
      {
        value: "Amount",
        label: "金額",
      },
      {
        value: "Percent",
        label: "折數",
      },
    ];

    const reseatForm = () => {
      Object.assign(form, {
        once: true,
        number: 1,
        datetimerange: [],
        mode: "Amount",
        discount: null,
        title: "",
        description: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "確定要新增優惠券嗎?",
            icon: "warning",
            text: "新增後將無法刪除!",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                space: currentSpaceGuid,
                once: form.once,
                number: form.number,
                mode: form.mode,
                discount:
                  form.mode === "Percent"
                    ? form.discount * 0.01
                    : form.discount,
                beginTime: getUtcTimeString(form.datetimerange[0]),
                endTime: getUtcTimeString(form.datetimerange[1]),
                title: form.title,
                description: form.description,
              };
              console.log("request", request);
              await addCoupon(request);
              await Swal.fire("新增成功!", "", "success");
              emit("onAddCoupon");
              reseatForm();
            }
          });
        }
      });
    };

    onMounted(() => {
      let addCouponModal: any = document.getElementById(
        "addCoupon-modal"
      );
      addCouponModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
      modeOptions,
    };
  },
});
</script>

<style>
</style>