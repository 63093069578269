
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getCouponsBySpace } from "@/core/services/api/coupons";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import CouponDetail from "./components/CouponDetail.vue";
import AddCoupon from "./components/AddCoupon.vue";
import EditCoupon from "./components/EditCoupon.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    Datatable,
    CouponDetail,
    AddCoupon,
    EditCoupon
  },
  emits:['onAddCoupon','onEditAddCoupon'],
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let addCouponModal;
    let editCouponModal;
    const currentItem = reactive({});
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      { key: "title", name: "優惠標題" },
      { key: "program", name: "優惠模式", sortable: true },
      { key: "mode", name: "優惠折扣", sortable: true },
      { key: "codesLength", name: "優惠數量", sortable: true },
      { key: "beginTime", name: "開始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "operate", name: "功能" },
    ]);
    const isLoadind = ref(false);
    const couponModeDictionary = {
      Amount: "金額",
      Percent: "折數",
    };
    const getCoupon = async () => {
      let request = {
        space: currentSpaceGuid,
      };
      // 取得空間中優惠券
      let coupon = await getCouponsBySpace(request);
      // 取出標題不為空的優惠券
      coupon = coupon.filter((o) => o.title !== "");
      // 取出所有優惠券唯一識別
      let couponGuids = coupon.map((o) => o.guid);
      // 去除重複
      couponGuids = Array.from(new Set(couponGuids));

      let filteredCouponData: any = [];
      // 遍歷所有優惠券唯一識別
      for (const o of couponGuids) {
        // 取出此唯一識別優惠券
        let couponData: any = coupon.filter((c) => c.guid == o)[0];
        let allCouponData: any = coupon.filter((c) => c.guid == o);

        // 優惠券資料中加入優惠券數量欄位
        couponData.dataCount = couponData.once
          ? allCouponData.length
          : "重複使用";

        // 將資料加入陣列
        filteredCouponData.push(couponData);
      }

      return filteredCouponData;
    };

    const getDiscount = (row) => {
      if (row.mode === "Amount") {
        return row.discount;
      } else {
        const percent = row.discount * 10;
        if (percent === 0) {
          return "全折底";
        } else {
          return percent + "折";
        }
      }
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let coupon = await getCoupon();
      tableData.splice(0);
      for (const o of coupon) {
        tableData.push({
          coupon: o,
          title: o.title,
          program: couponModeDictionary[o.mode],
          mode: getDiscount(o),
          codesLength: o.dataCount,
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
        });
      }
      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    const onAddCoupon = () => {
      setTableData()
      addCouponModal.hide()
    }

    const onEditAddCoupon = () => {
      setTableData()
      editCouponModal.hide()
    }

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    onMounted(() => {
      addCouponModal = new Modal(
        document.getElementById("addCoupon-modal")
      );
      editCouponModal = new Modal(
        document.getElementById("editCoupon-modal")
      );
    });

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      search,
      tableData,
      initTableData,
      tableHeader,
      isLoadind,
      tableSearch,
      currentItem,
      setCurrentItem,
      onAddCoupon,
      onEditAddCoupon
    };
  },
});
