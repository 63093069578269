<template>
  <el-form
    @submit.prevent="submit()"
    :model="form"
    :rules="rules"
    ref="formRef"
    class="form"
  >
    <div>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">起訖時間</span>
      </label>
      <el-form-item prop="datetimerange">
        <el-date-picker
          v-model="form.datetimerange"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
        >
        </el-date-picker>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">標題</span>
      </label>
      <el-form-item prop="title">
        <el-input v-model="form.title" placeholder="請輸入標題"></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span>內容</span>
      </label>
      <el-form-item>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="請輸入內容"
          v-model="form.description"
        >
        </el-input>
      </el-form-item>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { editCoupon } from "@/core/services/api/coupons";

export default defineComponent({
  props: ["coupon"],
  setup(props,{emit}) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      datetimerange: [
        {
          required: true,
          message: "請填選起訖時間",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "請填寫標題",
          trigger: "blur",
        },
      ],
    });
    const form: any = reactive({
      datetimerange: [],
      title: "",
      description: "",
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "確定要編輯優惠券嗎?",
            icon: "warning",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                guid: props.coupon.coupon.guid,
                beginTime: getUtcTimeString(form.datetimerange[0]),
                endTime: getUtcTimeString(form.datetimerange[1]),
                title: form.title,
                description: form.description,
              };
                await editCoupon(request);
                await Swal.fire("編輯成功!", "", "success");
                emit("onEditAddCoupon");
            }
          });
        }
      });
    };

    const setFormData = () => {
      let coupon = props.coupon.coupon;
      Object.assign(form, {
        datetimerange: [
          getLocalTimeString(coupon.beginTime),
          getLocalTimeString(coupon.endTime),
        ],
        title: coupon.title,
        description: coupon.description,
      });
    };

    watch(
      props,
      () => {
        setFormData();
      },
      { deep: true }
    );

    return {
      rules,
      formRef,
      form,
      submit,
    };
  },
});
</script>

<style>
</style>