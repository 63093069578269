<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <div class="card-title d-flex justify-content-between">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#addCoupon-modal"
        >
          新增優惠券
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-title="{ row: tableData }">
          {{ tableData.title }}
        </template>
        <template v-slot:cell-program="{ row: tableData }">
          {{ tableData.program }}
        </template>
        <template v-slot:cell-mode="{ row: tableData }">
          {{ tableData.mode }}
        </template>
        <template v-slot:cell-codesLength="{ row: tableData }">
          {{ tableData.codesLength }}
        </template>
        <template v-slot:cell-beginTime="{ row: tableData }">
          {{ tableData.beginTime }}
        </template>
        <template v-slot:cell-endTime="{ row: tableData }">
          {{ tableData.endTime }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentItem(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#couponDetail-modal"
                  >詳細</el-dropdown-item
                >
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#editCoupon-modal"
                  >編輯</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 優惠券詳細資訊modal -->
  <div
    class="modal fade"
    id="couponDetail-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="couponDetail-exampleModalLabel">
            詳細使用紀錄({{ currentItem.title }})
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <CouponDetail :coupon="currentItem"></CouponDetail>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增優惠券modal -->
  <div
    class="modal fade"
    id="addCoupon-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addCoupon-exampleModalLabel">
            新增優惠券
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddCoupon @onAddCoupon="onAddCoupon()"></AddCoupon>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯優惠券modal -->
  <div
    class="modal fade"
    id="editCoupon-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editCoupon-exampleModalLabel">
            編輯優惠券
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <EditCoupon @onEditAddCoupon="onEditAddCoupon()" :coupon="currentItem"></EditCoupon>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getCouponsBySpace } from "@/core/services/api/coupons";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import CouponDetail from "./components/CouponDetail.vue";
import AddCoupon from "./components/AddCoupon.vue";
import EditCoupon from "./components/EditCoupon.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    Datatable,
    CouponDetail,
    AddCoupon,
    EditCoupon
  },
  emits:['onAddCoupon','onEditAddCoupon'],
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let addCouponModal;
    let editCouponModal;
    const currentItem = reactive({});
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      { key: "title", name: "優惠標題" },
      { key: "program", name: "優惠模式", sortable: true },
      { key: "mode", name: "優惠折扣", sortable: true },
      { key: "codesLength", name: "優惠數量", sortable: true },
      { key: "beginTime", name: "開始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "operate", name: "功能" },
    ]);
    const isLoadind = ref(false);
    const couponModeDictionary = {
      Amount: "金額",
      Percent: "折數",
    };
    const getCoupon = async () => {
      let request = {
        space: currentSpaceGuid,
      };
      // 取得空間中優惠券
      let coupon = await getCouponsBySpace(request);
      // 取出標題不為空的優惠券
      coupon = coupon.filter((o) => o.title !== "");
      // 取出所有優惠券唯一識別
      let couponGuids = coupon.map((o) => o.guid);
      // 去除重複
      couponGuids = Array.from(new Set(couponGuids));

      let filteredCouponData: any = [];
      // 遍歷所有優惠券唯一識別
      for (const o of couponGuids) {
        // 取出此唯一識別優惠券
        let couponData: any = coupon.filter((c) => c.guid == o)[0];
        let allCouponData: any = coupon.filter((c) => c.guid == o);

        // 優惠券資料中加入優惠券數量欄位
        couponData.dataCount = couponData.once
          ? allCouponData.length
          : "重複使用";

        // 將資料加入陣列
        filteredCouponData.push(couponData);
      }

      return filteredCouponData;
    };

    const getDiscount = (row) => {
      if (row.mode === "Amount") {
        return row.discount;
      } else {
        const percent = row.discount * 10;
        if (percent === 0) {
          return "全折底";
        } else {
          return percent + "折";
        }
      }
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let coupon = await getCoupon();
      tableData.splice(0);
      for (const o of coupon) {
        tableData.push({
          coupon: o,
          title: o.title,
          program: couponModeDictionary[o.mode],
          mode: getDiscount(o),
          codesLength: o.dataCount,
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
        });
      }
      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    const onAddCoupon = () => {
      setTableData()
      addCouponModal.hide()
    }

    const onEditAddCoupon = () => {
      setTableData()
      editCouponModal.hide()
    }

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    onMounted(() => {
      addCouponModal = new Modal(
        document.getElementById("addCoupon-modal")
      );
      editCouponModal = new Modal(
        document.getElementById("editCoupon-modal")
      );
    });

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      search,
      tableData,
      initTableData,
      tableHeader,
      isLoadind,
      tableSearch,
      currentItem,
      setCurrentItem,
      onAddCoupon,
      onEditAddCoupon
    };
  },
});
</script>

<style>
</style>