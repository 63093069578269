import ApiService from "@/core/services/ApiService";

// 根據優惠卷查詢訂單紀錄
export function getOrderRecord(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`OrderRecord/QueryByCoupon`, request).then(res => {
            resolve(res.data.orderRecords)
        }).catch(err => {
            reject(err)
        })
    })
}